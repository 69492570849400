import React, { useState, useRef } from "react"
import { graphql } from "gatsby"

import Layout from "../mainComponents/Layout"
import Footer from "../mainComponents/Footer"
import FooterMail from "../components/FooterMail"
import SEO from "../mainComponents/seo"

import { useMediaQuery } from "../components/isMediaSmall"

import "../style/survey.scss"
import Wrapper from "../mainComponents/Wrapper"

export const query = graphql`
  query SurveyQuery {
    allSurveyDataJson {
      edges {
        node {
          id
          title
          options {
            id
            icon
            title
          }
        }
      }
    }
  }
`

const SurveyPage = ({ data }) => {
  const [quizOne, setQuizOne] = useState([])
  const [quizTwo, setQuizTwo] = useState([])
  const [quizThree, setQuizThree] = useState({})
  const [quizFour, setQuizFour] = useState({})

  const [step, setStep] = useState(1)
  const totalStep = 4

  const surveyData = data.allSurveyDataJson.edges

  const quizRef = useRef();
  const inputRef = useRef();

  const isMediaSmall = useMediaQuery("(max-width: 1140px)");

  const scrollToElem = () => {
    if(isMediaSmall) {
      quizRef.current.scrollIntoView({behavior: "smooth"});
    }
  }

  const handleQuizDone = () => {
    setQuizOne([]);
    setQuizTwo([]);
    setQuizThree({});
    setQuizFour({});
    setStep(1);
    scrollToElem();
  }

  const changeStep = type => {
    scrollToElem();

    switch (type) {
      case "prev":
        if (step === 1) {
          setStep(1)
        } else {
          setStep(prevState => prevState - 1)
        }
        break
      case "next":
        switch (step) {
          case 1:
            if(quizOne.length > 0) {
              setStep(prevState => prevState + 1)
            }
            break;
          case 2:
            if(quizTwo.length > 0) {
              setStep(prevState => prevState + 1)
            }
            break;
          case 3:
            if(Object.keys(quizThree).length > 0) {
              setStep(prevState => prevState + 1)
            }
            break;
          case 4:
            if(Object.keys(quizFour).length > 0) {
              setStep(prevState => prevState + 1)
            }
            break;

          default:
            break;
        }
        break

      default:
        break
    }
  }

  const handleRadioCheckbox = (type, quizId, itemId, itemName) => {
    switch (type) {
      case "checkbox":
        switch (quizId) {
          case 1:
            if(quizOne.find(({id}) => id === itemId)) {
              setQuizOne(quizOne.filter(elem => elem.id !== itemId));
            } else {
              setQuizOne(prevState => [
                ...prevState,
                {
                  id: itemId,
                  name: itemName,
                },
              ])
            }
            break
          case 2:
            if(quizTwo.find(({id}) => id === itemId)) {
              setQuizTwo(quizTwo.filter(elem => elem.id !== itemId));
            } else {
              setQuizTwo(prevState => [
                ...prevState,
                {
                  id: itemId,
                  name: itemName,
                },
              ])
            }
            break
          default:
            break
        }
        break

      case "radio":
        switch (quizId) {
          case 3:
            setQuizThree({
              id: itemId,
              name: itemName,
            })
            break
          case 4:
            setQuizFour({
              id: itemId,
              name: itemName,
            })
            break
          default:
            break
        }
        break

      default:
        break
    }
  }

  return (
    <Wrapper>
    <Layout pageName="survey">
      <SEO title="Survey" description={"Take a quick survey so we could efficiently help you with your software development request."} />

      <section className="survey">
        <div className="container">
          <h2 className="survey__title">
            Request a project and get free estimates for timeline and pricing
          </h2>

          <p className="survey__text">
            Let’s turn your idea into digital reality! Just answer our
            interactive questions and we will draft the best product offer for
            you. Our business analysts will study your brand, make their
            conclusions, and draft most suitable proposals. We provide free
            estimation and IT consulting for our clients. So don’t hesitate
            to contact us.</p>

          <h4 className="survey__subtitle">Let’s try our interactive survey</h4>
        </div>
      </section>

      {step > totalStep ? null : (
        <section ref={quizRef} className="quiz">
          <div className="container">
            {surveyData.map(({ node }) =>
              +node.id === step ? (
                <div className="" key={node.id}>
                  <div className="quiz__block">
                    <h3 className="quiz__title">{node.title}</h3>

                    {isMediaSmall ? null : <>
                    <svg
                      className={`quiz__arrow${step === 1 ? " hide" : ""}`}
                      onClick={() => changeStep("prev")}
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        r="23.5"
                        transform="matrix(-1 0 0 1 24 24)"
                        stroke="#1958E3"
                      />
                      <path
                        d="M26.5 19L22 23.5L26.5 28"
                        stroke="#1958E3"
                        strokeWidth="3"
                      />
                    </svg>

                    <svg
                      className={`quiz__arrow${
                      step === 1 && quizOne.length > 0 ? " active" : 
                      step === 2 && quizTwo.length > 0 ? " active" :
                      step === 3 && Object.keys(quizThree).length > 0 ? " active" :
                      step === 4 && Object.keys(quizFour).length > 0 > 0 ? " active" : ""}`}
                      onClick={() => changeStep("next")}
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="24" cy="24" r="23.5" stroke="#1958E3" />
                      <path
                        d="M22 19L26.5 23.5L22 28"
                        stroke="#1958E3"
                        strokeWidth="3"
                      />
                    </svg>
                    </>}

                    <h5 className="quiz__step">
                      step {step}
                      <span className="quiz__step--span">/5</span>
                    </h5>
                  </div>

                  <div className="quiz__block">
                    {node.options.map(item => (
                      <div className="quiz-item" key={item.id}>
                        <input
                          ref={inputRef}
                          id={`quizItem-${node.id}-${item.id}`}
                          className="quiz-item__checkbox"
                          type={`${
                            +node.id === 3 || +node.id === 4
                              ? "radio"
                              : "checkbox"
                          }`}
                          name={`quiz-${node.id}`}
                          checked={
                            +node.id === 1 ?
                            quizOne.find(({id}) => id === item.id) :
                            +node.id === 2 ?
                            quizTwo.find(({id}) => id === item.id) :
                            +node.id === 3 ?
                            quizThree.id === item.id :
                            +node.id === 4 ?
                            quizFour.id === item.id :
                            false
                          }
                          onChange={() =>
                            handleRadioCheckbox(
                              +node.id === 3 || +node.id === 4
                                ? "radio"
                                : "checkbox",
                              +node.id,
                              item.id,
                              item.title
                            )
                          }
                        />

                        <label
                          className="quiz-item__label"
                          htmlFor={`quizItem-${node.id}-${item.id}`}
                        >
                          {item.icon ? (
                            <img
                              className="quiz-item__icon"
                              src={item.icon}
                              alt=""
                            />
                          ) : null}
                          <h5 className="quiz-item__title">{item.title}</h5>
                        </label>
                      </div>
                    ))}
                  </div>

                  <button
                    className="quiz-btn"
                    onClick={() => changeStep("next")}
                  >
                    {totalStep === step ? "Last step" : "Next question"}
                    <svg
                      className="quiz-btn__svg"
                      width="48"
                      height="16"
                      viewBox="0 0 48 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M47.7071 8.70711C48.0976 8.31658 48.0976 7.68342 47.7071 7.29289L41.3431 0.928932C40.9526 0.538408 40.3195 0.538408 39.9289 0.928932C39.5384 1.31946 39.5384 1.95262 39.9289 2.34315L45.5858 8L39.9289 13.6569C39.5384 14.0474 39.5384 14.6805 39.9289 15.0711C40.3195 15.4616 40.9526 15.4616 41.3431 15.0711L47.7071 8.70711ZM0 9H47V7H0V9Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              ) : null
            )}
          </div>
        </section>
      )}
    </Layout>

    <Footer>
      {quizOne.length > 0 && quizTwo.length > 0 && Object.keys(quizThree).length > 0 && Object.keys(quizFour).length > 0 && step > totalStep ? <FooterMail ref={quizRef} pageName="survey" pageData={{
        "quizOne": quizOne,
        "quizTwo": quizTwo,
        "quizThree": quizThree,
        "quizFour": quizFour
      }} handleQuizDone={handleQuizDone} /> : null}
    </Footer>
    </Wrapper>
  )
}

export default SurveyPage
